<div class="progress">
    <kendo-tilelayout>
        <kendo-tilelayout-item>
            <kendo-tilelayout-item-body>
                <form novalidate class="k-form" [formGroup]="semesterForm">
                    <div class="dropdownBlock">
                        <div class="dropdownChild">
                            <h2 class="dropdownTitle">Выбрать период</h2>
                        </div>
                        <div class="dropdownChild">
                            <kendo-dropdownlist
                            [data]="studentSemesters"
                            textField="fullCourseName"
                            (valueChange)="valueChange($event)"
                            valueField="semestrNum"
                            style="width: 356px"
                            class="montserrat"
                            [valuePrimitive]="true"
                            [(ngModel)]="lastSemester"
                            [formControl]="$any(semesterForm).controls['semestrNum']"
                            formControlName="semestrNum" #semestrNum required>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                </form>
                <!-- Промежуточный контроль -->
            <div *ngIf="!isStatementDiscipline"> 
                <h2 class="progress__title">Промежуточный контроль</h2>
                <kendo-grid
                [data]="middleControls"
                [loading]="loading"
                scrollable="none">
                    <kendo-grid-column headerClass="grid-header" field="disciplineName" title="Дисциплина" [width]="200">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell leftAlignment">
                                {{dataItem.disciplineName + ' (' + dataItem.controlActionName + ')'}}
                            </span>
                        </ng-template>

                    </kendo-grid-column>
                    <kendo-grid-column-group title="Сессия" headerClass="grid-header">
                        <kendo-grid-column headerClass="grid-header" field="markView" title="Оценка" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.markView}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column headerClass="grid-header" [hidden]="true" field="ratingScore" title="Балл рейтинга" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.ratingScore}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column headerClass="grid-header" field="markDate" title="Дата получения" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.markDate | date:'dd.MM.YYYY':'GMT'}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column headerClass="grid-header" field="retakes" title="Пересдачи" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell centerAlignment">
                                    {{dataItem.retakeCount}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid-column-group>
                </kendo-grid>
                <!-- Текущий контроль -->
                <div *ngIf="!hideCurrentControl && !lkoStudyProgress">
                    <h2 class="progress__title">Текущий контроль</h2>
                    <kendo-grid [kendoGridBinding]="currentControls"
                    [loading]="currentControlLoading"
                    scrollable="none">
                        <kendo-grid-column headerClass="grid-header" field="disciplineName" title="Дисциплина" [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="gridCell leftAlignment">
                                    {{dataItem.disciplineName + ' (' + dataItem.controlActionName + ')'}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column-group headerClass="grid-header" *ngFor="let item of dictAttestations" [title]="item.name">
                            <kendo-grid-column headerClass="grid-header" field="ball" title="Оценка" [width]="80" format="n0">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell centerAlignment">
                                        {{currentControlMarks(item.externalId, dataItem, 'ball')}}
                                    </span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column headerClass="grid-header" field="hoursAbsent" title="Пропущенные часы" [width]="80" format="n0">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="gridCell centerAlignment" >
                                        {{currentControlMarks(item.externalId, dataItem, 'hoursAbsent')}}
                                    </span>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid-column-group>
                    </kendo-grid>
                </div>
              <div class="currentControl" *ngIf="!hideCurrentControl && lkoStudyProgress">
                <a class="mail-link mw-200" href="{{ lkoStudyProgress }}" target="_blank">Текущий контроль</a>
              </div>

              <!--Текущий контроль ext-->
              <div>
                <h2 class="progress__title">Текущий контроль</h2>
                <kendo-grid [data]="disciplines"
                            [loading]="disciplinesLoading"
                >
                    <kendo-grid-column headerClass="grid-header" field="dictDiscipline" title="Дисциплина" [width]="300">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell leftAlignment">
                              <span kendoTooltip position="bottom" *ngIf="dataItem.status">
                                <span class="k-icon item-icon {{ getIcon(dataItem.status.attendanceStatus) }} size24"
                                      [style.color]="dataItem.status.color"
                                      class="m-r10"
                                      title="{{dataItem.status.text}}"
                                ></span>
                              </span>
                              <span class="discipline" (click)="openStatement(dataItem)" [ngClass]="{bold: !dataItem.controllingActionId}">{{ dataItem.dictDiscipline }}</span>
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" field="two" title="Количество «2»" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center" [ngClass]="{red: dataItem.two > 0}">
                             {{ dataItem.two }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" field="missing" title="Количество пропусков" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center" [ngClass]="{red: dataItem.missing > 0}">
                                {{ dataItem.missing }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" field="average" title="Общий балл" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center">
                                {{ dataItem.average ? formatNumber(dataItem.average) : '' }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>

                <kendo-grid [data]="physicalSports" class="m-t20"
                            [loading]="physicalLoading"
                >
                    <kendo-grid-column headerClass="grid-header" field="disciplineName" title="Дисциплина" [width]="500">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell leftAlignment">
                                <p (click)="openStatementPhysical(dataItem.name)">{{ dataItem.name }}</p>
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" field="disciplineName" title="Общий балл" [width]="150">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center">
                           
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
              </div>
            </div>

            <div *ngIf="isStatementDiscipline">
                <h2>Лист текущего контроля по дисциплине "{{ discipline }}"</h2>
                <kendo-grid [data]="statementDisciplines"
                            [loading]="statementLoading"
                >
                    <kendo-grid-column headerClass="grid-header" field="name" title="Тема" [width]="400">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell leftAlignment">
                              {{ dataItem.name }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" *ngIf="isPhysical"
                                       field="disciplineName"
                                       title="Вид работы"
                                       [width]="200">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell leftAlignment">

                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" field="date" title="Дата" [width]="180">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center">
                                {{ dataItem.date | date: "dd.MM.yyyy" }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" field="attendedType" title="Посещение" [width]="130">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center">
                                <span *ngIf="dataItem.attendedType == attendedEnum.attend">+</span>
                                <span *ngIf="dataItem.attendedType == attendedEnum.absence" class="red">нб</span>
                            </span>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate *ngIf="lessonType == lessonTypeEnum.Practice || isPhysical">
                          <span class="gridCell center">
                            Cредний балл
                          </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" field="marks" title="Оценка" [width]="180">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center">
                                {{ dataItem.marks.join() }}
                            </span>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate *ngIf="lessonType == lessonTypeEnum.Practice">
                          <span class="gridCell center">
                            {{ average }}
                          </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column headerClass="grid-header" *ngIf="isPhysical"
                                       field="disciplineName"
                                       title="Балл"
                                       [width]="180"
                    >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="gridCell center">

                            </span>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate>
                            {{ average }}
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
                <div class="end m-t20">
                    <button kendoButton (click)="back()">Назад</button>
                </div>
            </div>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
    </kendo-tilelayout>
</div>
