import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class IotEmailSubscriptionService {
  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.iot}`;

  constructor(private http: HttpClient) { }

  public subscribeStudentToEmail() {
    return this.http.post(`${this.baseUrl}/SubscribeStudentToEmail`, {});
  }

  public unsubscribeStudentFromEmail() {
    return this.http.post(`${this.baseUrl}/UnsubscribeStudentFromEmail`, {});
  }

  public getStudentSubscriptionEmailStatus() {
    return this.http.get<boolean>(`${this.baseUrl}/GetStudentSubscriptionEmailStatus`);
  }
}
