import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HttpClient, HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from '@progress/kendo-angular-menu';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import 'hammerjs';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { ListViewModule } from '@progress/kendo-angular-listview';
import "@progress/kendo-angular-intl/locales/ru/all"
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import {HomeComponent} from "./components/home/home.component";
import { TimeTableComponent } from './components/timetable/timetable.component';
import { ExamListComponent } from './components/exam-list/exam-list.component';
import { ProgressComponent } from './components/progress/progress.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { EducationComponent } from './components/education/education.component';
import { EventsComponent } from './components/home/events/events/events.component';
import { ExamsComponent } from './components/home/exams/exams.component';
import { StudentComponent } from './components/home/student/student.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import {CallBackComponent} from "./components/auth/callback.component";
import {LoginpageComponent} from "./components/auth/loginpage.component";
import {JWT_OPTIONS, JwtModule} from "@auth0/angular-jwt";
import {TokenStorageService} from "./services/token.service";
import {jwtOptionsFactory} from "./services/jwt";
import {AuthGuard} from "./guards/authguard";
import {TokenInterceptor} from "./interceptors/token.interceptor";
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { EducationDisciplineComponent } from './components/education/education-discipline/education-discipline.component';
import { FilterUniquePipe } from './pipes/filter-unique.pipe';
import { CompetenceMatrixComponent } from './components/education/competence-matrix/competence-matrix.component';
import { MessageService } from '@progress/kendo-angular-l10n';
import { MyMessageService } from './message-service.service';
import { ShowComponent } from './components/announcement/show/show.component';
import { CommentsComponent } from './components/announcement/show/comments/comments.component';
import { EducationalProcessScheduleCellComponent } from './components/education/educational-process-schedue-cell/educational-process-schedule-cell.component';
import { AlertComponent} from "./components/announcement/alert/alert.component";
import { PagerModule } from '@progress/kendo-angular-pager';
import { SwitchuserComponent } from './components/switchuser/switchuser.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ElectroResourcesComponent } from "./components/electro-resources/electro-resources.component";
import {EmploymentComponent} from "./components/employment/employment.component";
import {EmploymentInfoComponent} from "./components/employment/employment-info/employment-info.component";
import { SettingsComponent } from './components/home/settings/settings.component';
import { StudentsettingsComponent } from './components/home/studentsettings/studentsettings.component';
import { StudentcardsettingsComponent } from './components/home/studentcardsettings/studentcardsettings.component';
import {AchievementsHomeComponent} from "./components/achivements/home/achievements-home.component";
import {SimpleAchievementsComponent} from "./components/achivements/simple/simple-achievements.component";
import {AdvancedAchievementsComponent} from "./components/achivements/advanced/advanced-achievements.component";
import {OpenOnFocusDateDirective} from "./directives/open-on-focus-date.directive";
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import {ContactComponent} from "./components/home/contact/contact.component";
import {
  JoiningStudPersonToRegulationComponent
} from "./components/oferta/joining-stud-person-to-regulation/joining-stud-person-to-regulation.component";
import {OpenOnFocusDirective} from "./directives/open-on-focus.directive";
import {OpenOnFocusMultiselectDirective} from "./directives/open-on-focus-multiselect.directive";
import {OpenOnFocusAutocompleteDirective} from "./directives/open-on-focus-autocomplete.directive";
import {AfterValueChangedDirective} from "./directives/after-value-changed.directive";
import {MFCComponents} from "./routes/mfc-routes";
import {FilterMultiselectComponent} from "./common/filter-multiselect/filter-multiselect.component";
import {DateRangeFilterComponent} from "./common/date-range-filter/date-range-filter.component";
import { IotComponent } from './components/education/iot/iot/iot.component';
import {VisitLessonComponent} from './components/visitlesson/visitlesson.component';






registerLocaleData(localeRu)


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TimeTableComponent,
    ExamListComponent,
    ProgressComponent,
    FilterDropdownComponent,
    FilterMultiselectComponent,
    DateRangeFilterComponent,
    EducationComponent,
    EventsComponent,
    ExamsComponent,
    StudentComponent,
    LoginpageComponent,
    CallBackComponent,
    EducationDisciplineComponent,
    FilterUniquePipe,
    CompetenceMatrixComponent,
    ShowComponent,
    CommentsComponent,
    EducationalProcessScheduleCellComponent,
    AlertComponent,
    SwitchuserComponent,
    ElectroResourcesComponent,
    JoiningStudPersonToRegulationComponent,
    EmploymentComponent,
    EmploymentInfoComponent,
    SettingsComponent,
    StudentsettingsComponent,
    StudentcardsettingsComponent,
    AchievementsHomeComponent,
    SimpleAchievementsComponent,
    ContactComponent,
    AdvancedAchievementsComponent,
    OpenOnFocusDirective,
    OpenOnFocusMultiselectDirective,
    OpenOnFocusAutocompleteDirective,
    OpenOnFocusDateDirective,
    AfterValueChangedDirective,
    ...MFCComponents,
    IotComponent,
    VisitLessonComponent,
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DateInputsModule,
    DropDownsModule,
    DropDownListModule,
    ButtonsModule,
    GridModule,
    BrowserAnimationsModule,
    MenuModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    ChartsModule,
    NavigationModule,
    ListBoxModule,
    ListViewModule,
    HttpClientModule,
    IconsModule,
    ExcelModule,
    UploadsModule,
    DialogsModule,
    TooltipsModule,
    PagerModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenStorageService]
      }
    }),
    IndicatorsModule,
    EditorModule,
  ],
  providers: [
    AuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    { provide: MessageService, useClass: MyMessageService },
    { provide: LOCALE_ID, useValue: 'ru' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
