export const breadcrumbItemsMap = new Map<string, string>([
  ["", "Личный кабинет студента"],
  ["studentcardsettings", "Настройки отображения полей"],
  ["studyProgress", "Успеваемость"],
  ["education", "Образование"],
  ["portfolio", "Портфолио"],
  ['switchuser', 'Просмотр от другого лица'],
  ['alert', "Объявления и новости"],
  ['show',"Объявления и новости"],
  ['electroresources', "Электронные образовательные ресурсы и ЭБС"],
  ['employment', "Трудовая деятельность"],
  ['employmentInfo', "Добавление трудовой деятельности"],
  ['addcontact', "Добавление нового контакта"],
  ['editcontact', "Редактирование контакта"],
  ['mfc', 'МФЦ'],
  ['constructorForm', 'Форма заявки'],
  ['applicationTypeSelect', 'Заявка'],
  ['applicationForm', 'Заявка'],
])
