import {Component, OnInit} from '@angular/core';
import {CurrentControlExtService} from '../../services/LKStudyProgress/currentcontrolext.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AttendedModel} from '../../models/progress/visitLesson.model';

@Component({
    selector: 'lkvisitlesson',
    templateUrl: './visitlesson.component.html',
    styleUrls: ['./visitlesson.component.scss']
  })

  export class VisitLessonComponent implements OnInit {

    public qrId: string = '';
    public information: AttendedModel = new AttendedModel();

    public successfully: boolean = false;
    public error: boolean = false;
    public isWarning: boolean = false;
    public textError: string = "";

    constructor(
      private currentControlExtService: CurrentControlExtService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
    ) { 
        this.activatedRoute.params.subscribe((param) => {
          this.qrId = param['qrId'];
        })
    }
  
    ngOnInit(): void {
      this.checkAttended();
    }

    public checkAttended() {
      this.currentControlExtService.checkAttended(this.qrId)
        .subscribe(response => {
          this.information = response;
          this.successfully = true;
        },
        error => {
          if (error.error.isWarning) this.isWarning = true;
          this.error = true;
          this.textError = error.error.error;
      })
    }

    public route() {
      this.router.navigateByUrl(`/home`);
    }
}