<div>
  <button kendoButton class="submit-btn" [disabled]="cantSign" (click)="signStatement()">{{signed? "Заявление подписано": "Подписать заявление" }}</button>

  <div class="study-form">
    <span class="field">
    <kendo-label>Учебный год</kendo-label>
    <kendo-dropdownlist
      [data]="studentStudyYears"
      textField="yearName"
      valueField="studyYear"
      [valuePrimitive]="true"
      [(ngModel)]="yearAdmission"
      (valueChange)="LoadStudentElectiveDiscipline($event)"
    >
    </kendo-dropdownlist>
  </span>
  </div>

  <p class="notification-text" *ngIf="!studentElectiveDisciplines.length && hasLoaded">Нет доступных дисциплин для выбора</p>

  <div *ngIf="!isCurrentYear && this.hasDeadline">
    <p class="notification-text underline"><b>Важно!</b></p>
    <p class="notification-text">Выберите дисциплины по выбору, которые будете осваивать в следующем учебном году до {{ deadlineDateEnd ? DateToString(deadlineDateEnd, 'dd.MM.yyyy') : '' }}.<br>
      Обучающиеся, не выбравшие дисциплины в этот срок, будут распределены деканатами по наличию свободных мест в группах.</p>
  </div>

  <div *ngIf="!isCurrentYear && hasLoaded">
    <p class="notification-text email-subscription-text">{{studentEnabledEmailNotifications
      ? 'По кнопке “Отписаться от рассылки” вы больше не будете получать информацию об окончании сроков дисциплин на электронную почту.'
      : 'Рекомендуем подписаться на рассылку (кнопка "Подписаться на рассылку"),' +
      ' чтобы своевременно получать уведомления (за 2 дня, 1 день и 6 часов) на электронную почту о завершении выбора дисциплин.'}}</p>
    <button kendoButton type="button" class="email-subscription-button" (click)="openEmailDialog()">
      {{studentEnabledEmailNotifications ? 'Отписаться от рассылки' : 'Подписаться на рассылку'}}
    </button>
  </div>

  <div *ngIf="!isCurrentYear && deadlineDateEnd && deadlineDateEnd < today">
    <p class="notification-text">
      Выбранные дисциплины на учебный год. По вопросам закрепления или смены выборной дисциплины обращайтесь в ваш деканат.
    </p>
  </div>

  <div *ngIf="!isCurrentYear && !this.hasDeadline && !studentElectiveDisciplines.length">
    <p class="notification-text">Сроки записи на данный момент не определены, выбор дисциплин станет доступен после установки сроков.</p>
  </div>

  <div *ngIf="hasLoaded">
    <div  *ngFor="let studentElectiveDiscipline of studentElectiveDisciplines">
      <h5 class="discipline-title m-b15">{{studentElectiveDiscipline.disciplineInfo}}</h5>

      <div *ngIf="isCurrentYear && hasChosenElectiveDiscipline(studentElectiveDiscipline.electiveDisciplineDto)" class="disciplineInfo">
        <p>Выбранная дисциплина: {{getElectiveDisciplineName(studentElectiveDiscipline.electiveDisciplineDto)}} <br>
          <span *ngIf="getElectiveDisciplineLocation(studentElectiveDiscipline.electiveDisciplineDto)">
            Место проведений занятий: {{getElectiveDisciplineLocation(studentElectiveDiscipline.electiveDisciplineDto)}} <br>
          </span>
          <span *ngIf="getElectiveDisciplinePersonInfo(studentElectiveDiscipline.electiveDisciplineDto).trim()">
            Ответственный сотрудник: {{getElectiveDisciplinePersonInfo(studentElectiveDiscipline.electiveDisciplineDto)}}
          </span>
        </p>
      </div>

      <div *ngFor="let discipline of studentElectiveDiscipline.electiveDisciplineDto">
          <p *ngIf="discipline.hasSameElectiveDisciplineInPreviousStudyYear" class="notification-text red">
            Дисциплина по выбору "{{ discipline.electiveDisciplineName }}" изучалась в прошлом учебном году.</p>
      </div>

      <kendo-grid class="m-b15" [data]="studentElectiveDiscipline.electiveDisciplineDto">
        <kendo-grid-command-column
          title=""
          headerClass="grid-header dictionaries"
          [width]="90"
          *ngIf="true"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              *ngIf="!hasAnyEnrolled(studentElectiveDiscipline.electiveDisciplineDto) &&
                           !dataItem.hasEnrolled &&
                           dataItem.canMakeChoises &&
                           dataItem.studentsLeftToEnroll != 0 &&
                           dataItem.hasLimit"
              kendoButton type="button" class="edit-button" (click)="checkForSameElectiveDisciplineInStudyYear(dataItem)">
              Выбрать
            </button>
            <button *ngIf="dataItem.hasEnrolled && dataItem.canMakeChoises && dataItem.hasLimit" kendoButton type="button" class="edit-button" (click)="unroll(dataItem)">
              Отменить выбор
            </button>
            <button *ngIf="dataItem.hasEnrolled && !dataItem.canMakeChoises" kendoButton type="button" [disabled]="true" class="edit-button">
              Выбрано
            </button>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column field="electiveDisciplineName" title="Дисциплина" [width]="200" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="align-cells left-align" [ngClass]="{'red': dataItem.hasSameElectiveDisciplineInPreviousStudyYear}">
              {{dataItem.electiveDisciplineName}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="cathedraName" title="Кафедра" [width]="200" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="align-cells">{{dataItem.cathedraName}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="totalEnrolled" title="Количество свободных мест" [width]="200" headerClass="grid-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="align-cells" *ngIf="!dataItem.canMakeChoises">
              {{ '-' }}
            </span>
            <span *ngIf="dataItem.canMakeChoises && dataItem.hasLimit"
                  class="align-cells" [ngClass]="{'red': dataItem.studentsLeftToEnroll == 0}">
              {{ dataItem.studentsLeftToEnroll }}
            </span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>

</div>

<kendo-dialog title="Пожалуйста, подтвердите" class="change-dialog" *ngIf="openedDialog" (close)="closeDialog()" [height]="222" [width]="500">
  <div class="contentRow">
    <p class="contentRowText">Внимание! Дисциплина изучается в нескольких семестрах.<br>Хотите записаться?</p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Нет</button>
    <button kendoButton themeColor="primary" (click)="enroll()">Да</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Пожалуйста, подтвердите" class="change-dialog" *ngIf="openedEmailDialog" (close)="closeEmailDialog()" [height]="222" [width]="500">
  <div class="contentRow">
    <p class="contentRowText">
      {{studentEnabledEmailNotifications
      ? 'По кнопке “Отписаться от рассылки” вы больше не будете получать информацию об окончании сроков дисциплин на электронную почту.'
      : 'Вы действительно хотите получать уведомления на электронную почту?'}}
    </p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeEmailDialog()">Нет</button>
    <button kendoButton themeColor="primary" (click)="subscribeOrUnsubscribeStudentToEmail()">Да</button>
  </kendo-dialog-actions>
</kendo-dialog>
