export class DisciplinesModel {
    average?: number;
    controllingActionId?: string;
    dictDiscipline!: string;
    dictDisciplineId?: string;
    missing?: number;
    status?: {color: string, attendanceStatus: number, text: string};
    two?: number;
    lessonType!: number;
}

export class LessonModel {
    average!: number;
    discipline!: string;
    statementLessons!: StatementLessonsModel[];
}

export class StatementLessonsModel {
    attendedType!: number;
    date!: Date | string; 
    lessonExternalId!: string;
    marks!: number[];
    name!: string;
    sortNumber!: number;
}

export class AttendedModel {
    discipline!: string;
    date!: string;
    statementLesson!: string;
    group!: string;
    startTime!: string;
    endTime!: string;
}