import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {LessonModel, AttendedModel} from '../../models/progress/visitLesson.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentControlExtService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.currentcontrolext}`;
  
  constructor(private http: HttpClient) { }

    public getDisciplines(semester: number) {
      const data = {semester: semester};
      return this.http.post<{lecture: [], practice: []}>(this.baseUrl + '/LkoQuery', data);
    }

    public getLesson(controllingActionExternalId: string | undefined, lessonType: number) {
      const data = {
        controllingActionExternalId: controllingActionExternalId,
        lessonType: lessonType
      }
      return this.http.post<LessonModel>(this.baseUrl + '/LkoLessonSelectQuery', data);
    }

    public checkAttended(qrId: string) {
        return this.http.get<AttendedModel>(this.baseUrl + '/MarkItself/' + qrId);
    }
}