import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EducationComponent } from './components/education/education.component';
import { ExamListComponent } from './components/exam-list/exam-list.component';
import { HomeComponent } from './components/home/home.component';
import { ProgressComponent } from './components/progress/progress.component';
import { TimeTableComponent } from './components/timetable/timetable.component';
import {CallBackComponent} from "./components/auth/callback.component";
import {LoginpageComponent} from "./components/auth/loginpage.component";
import {AuthGuard} from "./guards/authguard";
import { EducationDisciplineComponent } from './components/education/education-discipline/education-discipline.component';
import { CompetenceMatrixComponent } from './components/education/competence-matrix/competence-matrix.component';
import { ShowComponent } from './components/announcement/show/show.component';
import { AlertComponent } from "./components/announcement/alert/alert.component";
import { SwitchuserComponent } from './components/switchuser/switchuser.component';
import { AdminGuard } from './guards/admin.guard';
import { ElectroResourcesComponent } from "./components/electro-resources/electro-resources.component";
import {EmploymentComponent} from "./components/employment/employment.component";
import {EmploymentInfoComponent} from "./components/employment/employment-info/employment-info.component";
import {SpecialModuleGuard} from "./guards/specialmodule.guard";
import {StudentcardsettingsComponent} from "./components/home/studentcardsettings/studentcardsettings.component";
import {AchievementsHomeComponent} from "./components/achivements/home/achievements-home.component";
import {ContactComponent} from "./components/home/contact/contact.component";
import {MfcRoutes} from "./routes/mfc-routes";
import {VisitLessonComponent} from './components/visitlesson/visitlesson.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', data: {title: 'Моя страница'} },
  { path: 'login', component: LoginpageComponent},
  { path: 'callback', component: CallBackComponent},
  { path: 'home', component: HomeComponent, data: {title: 'Моя страница'}, canActivate: [AuthGuard] },
  { path: 'home/studentcardsettings', component: StudentcardsettingsComponent, data: {title: 'Настройки отображения полей'}, canActivate: [AdminGuard] },
  { path: 'ExamList', component: ExamListComponent, data: {title: 'Расписание экзаменов'}, canActivate: [AuthGuard] },
  { path: 'TimeTable', component: TimeTableComponent, data: {title: 'Расписание занятий'}, canActivate: [AuthGuard] },
  { path: 'studyProgress', component: ProgressComponent, data: {title: 'Успеваемость'}, canActivate: [AuthGuard] },
  { path: 'education', component: EducationComponent, data: {title: 'Образование'} , canActivate: [AuthGuard] },
  { path: 'education/:tab', component: EducationComponent, data: {title: 'Образование'} , canActivate: [AuthGuard] },
  { path: 'portfolio', component: AchievementsHomeComponent, data: {title: 'Портфолио'} , canActivate: [AuthGuard] },
  { path: 'WIP', component: ExamListComponent, data: {title: 'В разработке'}, canActivate: [AuthGuard] },
  { path: 'education/discipline/:id', component: EducationDisciplineComponent, data: {title: 'Дисциплина'}, canActivate: [AuthGuard] },
  { path: 'education/competenceMatrix/:id', component: CompetenceMatrixComponent, data: {title: 'Перечень компетенций для дисциплины'}, canActivate: [AuthGuard] },
  { path: 'show/:id', component: ShowComponent, data: {title: 'Название новости'}, canActivate: [AuthGuard] },
  { path: 'alert', component: AlertComponent, data: {title: 'Объявления и новости'}, canActivate: [AuthGuard] },
  { path: 'switchuser', component: SwitchuserComponent, canActivate: [AdminGuard] },
  { path: 'electroresources', component: ElectroResourcesComponent, canActivate: [SpecialModuleGuard] },
  { path: 'employment', component: EmploymentComponent, data: {title: 'Трудовая деятельность'}, canActivate: [AuthGuard] },
  { path: 'employment/employmentInfo', component: EmploymentInfoComponent, data: {title: 'Добавить место работы'}, canActivate: [AuthGuard] },
  { path: 'employment/employmentInfo/:id', component: EmploymentInfoComponent, data: {title: 'Редактировать место работы'}, canActivate: [AuthGuard] },
  { path: 'addcontact', component: ContactComponent, data: {title: 'Добавить контакт'}, canActivate: [AuthGuard] },
  { path: 'editcontact/:contactTypeName/:contactId', component: ContactComponent, data: {title: 'Редактировать контакт'}, canActivate: [AuthGuard] },
  { path: 'visitlesson/:qrId', component: VisitLessonComponent, data: {title: ''}, canActivate: [AuthGuard] },
  { path: 'studyProgress/:disciplineId/:lessonType', component: ProgressComponent, data: {title: 'Успеваемость'}, canActivate: [AuthGuard] },
  ...MfcRoutes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
