export const StatusesListEnum = [
    {id: 0, icon: 'k-i-success'},
    {id: 1, icon: 'k-i-exception'},
    {id: 2, icon: 'k-i-error'},
    {id: 3, icon: 'k-i-minus-outline'},
]

export enum AttendedEnum {
    not = 0,
    absence = 1,
    attend = 2
}

export enum LessonType {
    Practice,
    Lecture,
}