export interface StudentElectiveDiscipline {
  disciplineInfo: string;
  deadlineDateEnd?: Date;
  electiveDisciplineDto: GetStudentsElectiveDisciplineDto[]
}

export interface GetStudentsElectiveDisciplineDto {
  electiveDisciplineName: string;
  electiveDisciplineId: string;
  cathedraName: string;
  studentsLeftToEnroll: number;
  hasSameElectiveDisciplineInStudyYear: boolean;
  hasSameElectiveDisciplineInPreviousStudyYear: boolean;
  canMakeChoises: boolean;
  hasEnrolled: boolean;
  signed: boolean;
  hasLimit: boolean;
  hasDeadline: boolean;
  electiveDisciplineStudentId: string;
  disciplineLocation: string;
  fio: string;
  phoneNumber: string;
}

export class AddElectiveDisciplineStudentCommand {
  public electiveDisciplineId: string;
  public studyYear: number;

  constructor(electiveDisciplineId: string, studyYear: number) {
    this.electiveDisciplineId = electiveDisciplineId;
    this.studyYear = studyYear;
  }
}

export class DeleteElectiveDisciplineStudentCommand {
  public electiveDisciplineStudentId: string;

  constructor(electiveDisciplineStudentId: string) {
    this.electiveDisciplineStudentId = electiveDisciplineStudentId;
  }
}

export class SignStatementCommand {
  public studyYear: number;

  constructor(studyYear: number) {
    this.studyYear = studyYear;
  }
}
