<h1 class="title">МФЦ</h1>

<button (click)="addApplication()"
        kendoButton
        class="k-button-solid-success"
        icon="plus">
  Добавить заявку
</button>

<kendo-grid class="grid-data borderless"
            [data]="gridData"
            [pageable]="pagerSettings"
            [pageSize]="state.take"
            [filterable]="true"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [loading]="loading"
            [skip]="state.skip"
            (pageChange)="pageChange($event)"
            (cellClick)="navigateToSelectedApplication($event)">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="number"
                     title="№ заявки"
                     [width]="130">
    <ng-template kendoGridFilterCellTemplate>
      <kendo-textbox (afterValueChanged)="filterChange($event, 'number')"
                     [clearButton]="true">
      </kendo-textbox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field] }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="createdAt"
                     title="Дата заявки"
                     [width]="320">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <date-range-filter [field]="column.field"
                         [filter]="filter"
                         (onFilterChange)="filterChange($event, 'createdAt')">
      </date-range-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field] | date:'dd.MM.yyyy HH:mm' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="category"
                     title="Категория"
                     [width]="200">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="applicationCategories"
                          gridField="category"
                          textField="name"
                          valueField="id"
                          (onFilterChange)="categoryChange($event)">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field].name }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="type"
                     title="Тип"
                     [width]="300">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="filterApplicationTypes"
                          (onFilterChange)="filterChange($event, 'type')"
                          gridField="type"
                          textField="name"
                          valueField="id">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field].name }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="status"
                     title="Текущий статус"
                     [width]="180">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="applicationStatuses"
                          (onFilterChange)="filterChange($event, 'statuses')"
                          textField="name"
                          valueField="applicationStatusEnum">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <div [ngClass]="{
                status: true,
                red: dataItem[column.field] === StatusEnum.Rejected,
                green: dataItem[column.field] === StatusEnum.Ready
              }">
        <span class="badge">
          <kendo-badge *ngIf="dataItem.notificationCount > 0">
            {{ dataItem.notificationCount }}
          </kendo-badge>
        </span>
        {{ statusMap.get(dataItem[column.field]) }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="statusDate"
                     title="Дата установки текущего статуса"
                     [width]="320">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <date-range-filter [field]="column.field"
                         [filter]="filter"
                         (onFilterChange)="filterChange($event, 'statusDate')">
      </date-range-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field] | date:'dd.MM.yyyy HH:mm' }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
